<div class="pill comet-border w-100 h-100 text-nowrap border-rad-6 fx-row-vert-cent pointer"
     (click)="goToWonSlots()">
  <div class="pill-inside1  border-rad-6 fx-row-vert-cent">
    <div class="pill-inside2 w-100 h-100 border-rad-6 fx-row-vert-cent pr-5">
      @if (!isMobile) {
        <span class="mina-icon icon-300 f-18 mr-5">schedule</span>
      }
      <div class="bp f-600 truncate">Block Production&nbsp;</div>
      <div class="time">
        @if (text) {
          {{ text }}
        } @else {
          @if (producingIn) {
            <strong>in</strong>
            {{ producingIn }}
          } @else {
            <strong>in ...</strong>
          }
        }</div>
    </div>
  </div>
</div>
