<mina-horizontal-menu [clz]="isMobile ? 'pl-12 pr-12' : ''"
                      [template]="template"></mina-horizontal-menu>

<ng-template #template>
  <div class="submenus fx-row-vert-cent flex-stretch h-100">
    <ng-container *ngFor="let subMenu of subMenus; let i=index">
      <div class="a-wrap fx-row-vert-cent">
        <a [class.active]="activeSubMenu === subMenu.route"
           [routerLink]="baseRoute + '/' + subMenu.route"
           [queryParams]="activeNodeName ? { node: activeNodeName } : null"
           [class.ml-8]="i > 0"
           class="text-capitalize f-600 flex-center flex-row flex-grow">
          {{ subMenu.name }}
        </a>
        <div *ngIf="i < subMenus.length - 1" class="bar border-right"></div>
      </div>
    </ng-container>
  </div>
</ng-template>
